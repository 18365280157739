<template>
  <div>
    <time-sheet-loader
      v-if="loader"
      :row-num="3"
    />
    <div
      v-else-if="items.length"
      class="emp-list"
    >
      <b-row>
        <b-col>
          <b-table
            :items="items"
            :fields="fields"
            show-empty
            responsive
            class="scrollable-element emp-list-data align-th-table tableDesigns"
          >
            <template #cell(name)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="36"
                  :src="data.item.user ? data.item.user.picture : ''"
                  :text="data.item.user.full_name | avatarText"
                  :variant="randomVariant(1, data.item.user)"
                />
                <div class="emp-detail">
                  <p class="emp-name">
                    {{ data.item.user.name }} {{ data.item.user.last_name }}
                  </p>
                </div>
              </div>
            </template>

            <template #cell(duration)="data">
              <div class="d-flex align-items-center">
                <span>{{ data.item.total_time | convertMinutes }}</span>
              </div>
            </template>

            <template #cell(week)="data">
              <div class="d-flex align-items-center">
                <span>{{
                  new Date(data.item.start_date).toLocaleDateString("en-us", {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })
                }}
                  -
                  {{
                    new Date(data.item.end_date).toLocaleDateString("en-us", {
                      weekday: "short",
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })
                  }}</span>
              </div>
            </template>

            <template #cell(status)="data">
              <div class="d-flex align-items-center">
                <span
                  v-if="data.item.approval_status == 'pending'"
                  :class="
                    data.item.approval_status == 'pending'
                      ? 'text-black-50'
                      : ''
                  "
                >
                  {{ data.item.approval_status }}
                </span>
                <span
                  v-if="data.item.approval_status == 'partial_approved'"
                  :class="
                    data.item.approval_status == 'pending'
                      ? 'text-black-50'
                      : ''
                  "
                >
                  Partial Approved
                </span>
                <span
                  v-if="data.item.approval_status == 'approved'"
                  :class="
                    data.item.approval_status == 'approved'
                      ? 'text-success'
                      : ''
                  "
                >
                  {{ data.item.approval_status }}
                </span>
                <span
                  v-if="data.item.approval_status == 'rejected'"
                  :class="
                    data.item.approval_status == 'rejected' ? 'text-danger' : ''
                  "
                >
                  {{ data.item.approval_status }}
                </span>
                <span
                  v-if="data.item.approval_status == 'in_process'"
                  :class="
                    data.item.approval_status == 'in_process'
                      ? 'text-warning'
                      : ''
                  "
                >
                  Submitted
                </span>
              </div>
            </template>

            <template #cell(action)="data">
              <span
                v-if="data.item.approval_status !== 'pending'"
                class="ml-2 pl-50"
              >
                <feather-icon
                  v-b-tooltip.hover
                  icon="EyeIcon"
                  size="18"
                  class="emp-eyeicon"
                  title="View"
                  @click="passUserId(data.item)"
                />
              </span>
              <span v-else> Not Submitted </span>
            </template>
          </b-table>
        </b-col>

      </b-row>

      <!-- pagination section -->
      <Pagination
        v-if="totalCount>0"
        :current-page="page"
        :per-page="perPage"
        :total-count="totalCount"
        @currentPage="updatePage($event)"
      />
      <!-- pagination section complete here -->

    </div>
    <div
      v-else
      class="w-100"
    >
      <img
        src="@/assets/images/nodatafound/nodata.svg"
        alt="no-data"
        class="no-data-img"
      >
    </div>
    <!-- Reject Resons modal -->
    <div>
      <b-modal
        id="updateStatus"
        v-model="modalStatus"
        ok-only
        ok-title="Accept"
        centered
        size="sm"
        class="addtask-modal rejected-modal"
        :show-ok="false"
      >
        <template #modal-title>
          <h3 class="addtask-from-title">
            <span> Rejected reason </span>
          </h3>
        </template>
        <validation-observer ref="rejectReasonRules">
          <b-row class="addtask-content">
            <!-- Select Title Block -->
            <b-col cols="12">
              <b-form-group
                label-for="title"
                class="m-1 to-do-input auto-resize-teaxtarea"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Rejected reason"
                  rules="required"
                >
                  <textarea
                    ref="textarea"
                    v-model="rejectedReasonMsg"
                    name="Rejected reason"
                    placeholder="Reason ..."
                    rows="5"
                    @input="resizeTextarea"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <template #modal-footer>
          <div class="text-center w-100">
            <div>
              <b-button
                variant="primary"
                size="sm"
                class="btnClose"
                @click="validationForm()"
              >
                Add Reason
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>
    </div>
    <!-- <UpdateEmpList/> -->
  </div>
</template>

<script>
import { BTable, BModal } from 'bootstrap-vue'
import { eventBus } from '@/main'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'EmployeeList',
  components: {
    BTable,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userId: {
      type: Array,
      require: false,
      default: () => ([]),
    },
    status: {
      type: Array,
      require: false,
      default: () => ([]),
    },
    perPage: {
      type: [String, Number],
      require: true,
      default: 100,
    },
    currentPage: {
      type: [String, Number],
      require: true,
      default: 1,
    },
    search: {
      type: [String],
      require: false,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        'name',
        { key: 'week', label: 'week', tdClass: 'emp-data' },
        { key: 'duration', label: 'duration', tdClass: 'emp-data' },
        { key: 'status', label: 'status', tdClass: 'status' },
        {
          key: 'action',
          label: 'Actions',
          thStyle: { width: '180px' },
          tdStyle: { width: '180px' },
        },
      ],
      items: [],
      loader: false,
      modalStatus: false,
      rejectedReasonMsg: null,
      rejectedId: null,
      totalCount: 0,
      page: 1,
    }
  },
  watch: {
    currentPage(nv) {
      this.page = nv
    },
    perPage(nv, old) {
      if (this.currentPage == 1) {
        this.getEmployeeList()
      } else if (nv != old) {
        this.currentPage = 1
      }
    },

    modalStatus(nv) {
      if (!nv) {
        this.rejectedReasonMsg = null
      }
    },
    search(nv) {
      if (!nv || nv.length > 3) {
        this.getEmployeeList()
      }
    },
  },
  mounted() {
    this.page = this.$route.query.currentPage || this.currentPage
    this.getEmployeeList()
    eventBus.$on('approvalsTimeSheetList', data => {
      if (data) {
        this.currentPage = 1
        this.getEmployeeList()
      }
    })
  },
  destroyed() {
    eventBus.$off('approvalsTimeSheetList')
  },
  methods: {
    /* */
    passUserId(data) {
      this.$emit('pageIndexUpdate')
      this.$router.push(`/approval/view/${data.timesheet_id}`)
    },

    /**
     * Get getEmployeeList
     */
    async getEmployeeList() {
      this.loader = true
      const input = {
        sort_order: this.sortDesc ? 'desc' : 'asc',
        approval_status: this.status ? this.status : null,
        user_id: this.userId ? this.userId : null,
        per_page: this.perPage,
        page: this.page,
        search: this.search,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/timesheet/list',
        input,
        false,
      )
      if (response && response.data) {
        const data = response.data.timesheet
        this.items = data
        this.totalCount = response.data.count
        this.items.forEach(element => {
          element.label = `${element.user.name} ${element.user.last_name}`
          element.value = element.user.user_id
        })
        this.$emit('user', this.items)
      }
      this.loader = false
    },

    /**
     * Update client status
     */
    async updateStatus(id, status) {
      this.loader = true
      id = id || this.rejectedId
      status = status || (this.rejectedId ? 'rejected' : null)
      const input = {
        type: 'timesheet',
        approval_status: status,
        rejection_reason: this.rejectedReasonMsg ? this.rejectedReasonMsg : '',
      }
      const response = await this.getHTTPPostResponse(
        `admin/master/timesheet/status/${id}`,
        input,
        true,
      )
      if (response && response.status == 200) {
        this.getEmployeeList()
      }
      this.loader = false
      this.modalStatus = false
    },

    /**
     * Add Rejected Reason
     */
    validationForm() {
      this.$refs.rejectReasonRules.validate().then(success => {
        if (success) {
          this.updateStatus()
        }
      })
    },

    /*
    * update Page
    */
    updatePage($event) {
      this.page = $event
      this.getEmployeeList()
      this.$emit('page', $event)
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/component-css/approvals.css";
#updateStatus___BV_modal_content_ {
  padding: 20px;
}
.approval-status-icon {
  color: #b8b8b8;
  cursor: pointer;
}
.emp-eyeicon {
  color: #b8b8b8;
  cursor: pointer;
}
</style>
